import { filterCapabilitiesForCloudTriggers, isRgb } from './EzloVirtualDevice/utils';
import templateBlock from '../../components/blockCloudTriggerTemplate';
import { getDeviceUUID } from './EzloMeshbot/utils';
import at from '../../constants/ActionTypes/MeshBot';
import { dateAndTimeNames } from '../../constants/rulesInSelect';
import {
    OPERATOR_NOT,
    MESHBOT_NODE_TYPES,
    TIME_NODE,
    IS_PM,
    OFFSET,
    TIMES_NODE,
    HOUR_NODE,
    SCHEDULE,
    HOUR12,
    ZERO_INT,
    INDEX_OF_ZERO,
    INDEX_OF_ONE,
    INDEX_OF_TWO,
    INDEX_SELECTED_FIELDS_ELEMENT,
    IS_SHOW_FOR_SECOND,
    CLOUD_NOTIFICATION,
    CLOUD_VARIABLE,
    NOT,
    GEOFENCE_BLOCK_INDEX,
    ON_CHANGE,
    SINGLE_TRIGGER,
    INITIAL_VALUE_FOR_COUNTER,
    AND,
} from '../../constants/MeshbotConstant';
import styles from './EzloMeshbot/components/TriggerBlock.module.scss';
import * as localStorageKeys from '../../constants/localStorageKeys';
import {
    DASHBOARD,
    EZLOGIC,
    ZERO,
    NOTIFICATIONS,
    DOT,
    UUID_VALIDATION_REGEXP,
    UUID_REGEXP,
    INCREMENT_BY_ONE,
    MESHBOTS_PAGE_VALUE,
} from './EzloCustomization/constants';

import _, { cloneDeep, isObject } from 'lodash';
import {
    DATA_FROM_VALUE_FROM_FIRST_BLOCK,
    DATA_FROM_VALUE_FROM_SECOND_BLOCK,
    NOTIFICATION_TEMPLATE,
} from '../../constants/NotificationTemplates';
import { CLOUD_MESHBOT, NOTIFICATION_TEMPLATE_ABSTRACT_UUID, MESHBOT_TYPES } from './EzloMeshbots/constants';
import { TYPE_FIELD_BOOLEAN } from './EzloRule/EditForm/RuleSettings/components/PAAS/paas-constants';
import { customTheme } from '../../helpers/customTheme';
import { filterDevicesByType } from './EzloDevices/utils';
import { CLOUD_VARIABLES, DEVICE_STATE } from './EzloMeshbot/constants';
import {
    EZLOGIC_TOAST_MULTIPLE_NUCAL_WARNING,
    EZLOGIC_TOAST_MULTIPLE_ONCHANGE_WARNING,
    EZLOGIC_TOAST_NUCAL_COMBINING_ANOTHER_ONCHANGE_TRIGGER_WARNING,
} from '../../constants/language_tokens';
import { ELEMENT_TYPES, ITEM_NAMES, OPERATIONS } from 'services/hub';
import { VALUE } from 'constants/App';
import { ONE_INT } from 'constants/Variables';
import { EZLOGIC_KNOWN_DOMAINS } from 'constants/URLs';

const { trigger_block, editing, is_valid } = styles;
/**
 * Returns array online controller MeshBot table
 * @param {object} data - object with controllers {serial:controller}
 * @returns {array} array of online controllers
 * */
export const getOnlineControllers = (data) => {
    const serialArray = [];
    for (const [key, value] of Object.entries(data)) {
        if (value.isConnected) {
            serialArray.push(key);
        }
    }

    return serialArray;
};

export const deviceOptions = (devices = [], rooms = []) => {
    const deviceArray = [];
    const filteredDevicesByType = filterDevicesByType(devices);
    filteredDevicesByType.map((option) => {
        const room = Array.isArray(rooms) && rooms?.find((room) => room._id === option.roomId);
        deviceArray.push({
            roomName: room?.name ? room.name : 'Unassigned',
            ...option,
        });
    });

    return sortOptionByRoomName(deviceArray);
};

export const sortOptionByRoomName = (deviceArray) => {
    return deviceArray.sort((a, b) => (a.roomName > b.roomName ? 1 : b.roomName > a.roomName ? -1 : 0));
};

export const filterAbstractOptions = (abstracts = []) => {
    if (!Array.isArray(abstracts)) {
        return [];
    }

    return abstracts.filter((item) => !item.capabilities.includes('scene_run') && !item.capabilities.includes('api'));
};

export const isControllerOffline = (controllers) => {
    const onlineControllers = Object.keys(controllers).filter((key) => {
        return controllers[key].isConnected;
    });

    return !onlineControllers.length;
};

export const isEzloPiController = (controllers) => {
    const matchingObjects = [];
    let ezloPiDevice = [];
    let ezloPiController = {};

    for (const key in controllers) {
        if (controllers[key].isConnected === true) {
            matchingObjects.push(key);
        }
    }

    if (matchingObjects.length > 0) {
        for (const key of matchingObjects) {
            ezloPiDevice = controllers[key].devices.filter((device) => device.deviceTypeId === MESHBOT_TYPES.EZLOPI);

            if (ezloPiDevice.length) {
                ezloPiController = { ...ezloPiController, [key]: controllers[key] };
            }
        }
    }

    return ezloPiController;
};

const isTargetValueEmpty = (target = {}) => {
    let isEmpty;
    if (Array.isArray(target)) {
        isEmpty = !!target.find((item) => !item.value.length);
    } else {
        isEmpty = !target?.value?.length;
    }

    return isEmpty;
};

/**
 * Returns validation className for action block
 * @param {object} action - object of current action
 * @param {array} actionsList - array of action List with ids
 * @param {array} initialActions - array of action list from currentScene without ids
 * @returns {string} string validation className for action block
 **/

export const isEditActionNotificationBlock = (action, actionsList, initialActions, notificationType) => {
    const findActionIndex = actionsList.findIndex((item) => item.id === action.id);

    const initialChannels =
        initialActions[findActionIndex]?.parameters &&
        initialActions[findActionIndex]?.parameters[INDEX_OF_ONE]?.targets &&
        initialActions[findActionIndex]?.parameters[INDEX_OF_ONE]?.targets[INDEX_OF_ZERO]?.channels?.length &&
        initialActions[findActionIndex]?.parameters[INDEX_OF_ONE]?.targets[INDEX_OF_ZERO]?.channels;
    const updatedChannels =
        action.notification?.parameters[INDEX_OF_ONE]?.targets[INDEX_OF_ZERO]?.channels?.length &&
        [...action.notification?.parameters[INDEX_OF_ONE]?.targets[INDEX_OF_ZERO]?.channels].sort();

    if (
        _.isEqual(action.notification, initialActions[findActionIndex]) &&
        _.isEqual(initialChannels, updatedChannels)
    ) {
        return `${trigger_block} ${editing}`;
    } else if (isValidActionBlock({ action, notificationType })) {
        return `${trigger_block} ${is_valid}`;
    } else {
        return `${trigger_block}`;
    }
};

export const isValidActionBlock = ({ action = {}, selectedRuleCloudNucal = [], notificationType = '' }) => {
    const {
        capability = '',
        capabilityCommands = [],
        command = '',
        target = {},
        notification = {},
        selectedFieldTrigger,
    } = action;

    if (
        selectedFieldTrigger === CLOUD_NOTIFICATION &&
        notification &&
        notification.parameters &&
        notification.parameters?.length &&
        notification.parameters?.[INDEX_OF_ZERO]?.body &&
        notification.parameters?.[INDEX_OF_ONE]?.targets &&
        notification.parameters?.[INDEX_OF_ONE]?.targets?.[INDEX_OF_ZERO] &&
        notification.parameters?.[INDEX_OF_ONE]?.targets?.[INDEX_OF_ZERO]?.channels?.length
    ) {
        return true;
    } else if (
        notificationType === NOTIFICATION_TEMPLATE &&
        notification &&
        notification.parameters &&
        notification.parameters?.length &&
        notification.parameters?.[DATA_FROM_VALUE_FROM_FIRST_BLOCK]?.body
    ) {
        return true;
    } else if (action.PAAS && action.PAAS?.parameters) {
        const { PAAS } = action;
        const params = PAAS?.parameters.find(({ name }) => name === 'params')?.params;

        if (selectedRuleCloudNucal?.length) {
            return selectedRuleCloudNucal.every((rule) => !!params?.[rule]?.length);
        } else {
            return (
                PAAS.parameters &&
                PAAS?.parameters[ZERO_INT].uuid &&
                PAAS?.parameters[INDEX_SELECTED_FIELDS_ELEMENT].value
            );
        }
    } else if (
        selectedFieldTrigger === DASHBOARD &&
        notification &&
        notification.parameters &&
        notification.parameters.length &&
        notification.parameters[INDEX_OF_ZERO].body &&
        notification.parameters[INDEX_OF_ONE].targets &&
        notification.parameters[INDEX_OF_ONE].targets[INDEX_OF_ZERO] &&
        notification.parameters[INDEX_OF_ONE].targets[INDEX_OF_ZERO].uuid &&
        notification.parameters[INDEX_OF_TWO].list[INDEX_OF_ZERO] &&
        !notification.parameters[INDEX_OF_TWO].list[INDEX_OF_ZERO]?.url?.includes(IS_SHOW_FOR_SECOND)
    ) {
        return true;
    }

    return !!(
        capability.length &&
        capabilityCommands.length &&
        command.length &&
        !isTargetValueEmpty(target) &&
        selectedFieldTrigger.length
    );
};

export const isActionChanged = (currentItem, currentSceneAction = {}) => {
    const { capability = '', command = '', target = {} } = currentItem;

    const { parameters } = currentSceneAction;

    const currentSceneActionsCapability = parameters?.[0]?.capability;
    const currentSceneActionsCommand = parameters?.[0]?.command;
    const currentSceneActionsValue = parameters?.[1]?.value;

    return !(
        (currentSceneActionsCapability === capability &&
            currentSceneActionsCommand === command &&
            currentSceneActionsValue === target.value) ||
        (currentSceneActionsValue === target[0]?.value &&
            currentSceneActionsCommand === command &&
            parameters?.[2]?.value === target?.[1]?.value) ||
        (currentSceneActionsValue === target?.[1]?.value && parameters?.[2]?.value === target?.[1]?.value)
    );
};

export const isValidTriggerBlock = (currentItem) => {
    const {
        deviceName,
        nameSubBlock,
        capabilities,
        variables,
        currentVariable,
        variableValue,
        isValidRgbValue,
        selectedOperator,
        selectedValueType,
    } = currentItem;

    const isValidDeviceBlock =
        deviceName?.length &&
        nameSubBlock?.length &&
        capabilities?.length &&
        currentVariable?.length &&
        variables?.length &&
        selectedValueType?.length &&
        variableValue?.length &&
        (isRgb(currentVariable) ? isValidRgbValue : true);
    const isValidDeviceOnChangeBlock = selectedOperator === ON_CHANGE;

    return !!(isValidDeviceBlock || isValidDeviceOnChangeBlock);
};

const areSubscriptionsEqual = (sub1, sub2) =>
    sub1?.uuid === sub2?.uuid && sub1?.parameters?.name === sub2?.parameters?.name;

export const hasDuplicateSubscriptions = (arr = []) => {
    for (let i = 0; i < arr.length; i++) {
        const sub1 = arr[i]?.subscription?.params || arr[i]?.subscriptionDataFromKvs;
        if (sub1) {
            for (let j = i + 1; j < arr.length; j++) {
                const sub2 = arr[j]?.subscription?.params || arr[j]?.subscriptionDataFromKvs;
                if (sub2 && areSubscriptionsEqual(sub1, sub2)) {
                    return false;
                }
            }
        }
    }

    return true;
};

export const isValidNucalBlock = (currentItem, ruleTriggers = []) => {
    const { selectedFieldTrigger, subscription, subscriptionDataFromKvs } = currentItem;

    let capability;

    if (!_.isEmpty(currentItem?.blocks?.[0])) {
        capability =
            currentItem?.blocks?.[0].name === NOT
                ? currentItem?.blocks?.[0]?.parameters[0]?.parameters[0]?.capability
                : currentItem?.blocks?.[0]?.parameters[0]?.capability;
    }

    if (ruleTriggers?.length < 2) {
        return !!(
            (selectedFieldTrigger?.length &&
                capability &&
                subscription?.id &&
                subscription?.params?.parameters?.name) ||
            subscriptionDataFromKvs?.parameters?.name
        );
    } else {
        return (
            !!(
                (selectedFieldTrigger?.length &&
                    capability &&
                    subscription?.id &&
                    subscription?.params?.parameters?.name) ||
                subscriptionDataFromKvs?.parameters?.name
            ) && hasDuplicateSubscriptions(ruleTriggers)
        );
    }
};
export const isValidNotificationTemplateBlock = (currentItem) => {
    return !!(
        currentItem?.capability?.length &&
        currentItem?.currentVariable?.length &&
        currentItem?.variableValue?.length &&
        currentItem?.isValidRgbValue
    );
};

export const isValidGeoFencingBlock = (currentItem) => {
    const { selectedFieldTrigger } = currentItem;
    const { parameters } =
        currentItem?.blocks?.[INDEX_OF_ZERO]?.name === NOT
            ? currentItem?.blocks?.[INDEX_OF_ZERO]?.parameters?.[GEOFENCE_BLOCK_INDEX]
            : currentItem?.blocks?.[GEOFENCE_BLOCK_INDEX];

    const location = parameters?.[INDEX_OF_ZERO]?.parameters?.[INDEX_OF_ZERO]?.value;
    const abstractUuid = parameters?.[INDEX_OF_ONE]?.parameters?.[INDEX_OF_ZERO]?.abstract;

    let name;

    if (!_.isEmpty(currentItem.blocks[0])) {
        name =
            currentItem.blocks[0].name === NOT
                ? currentItem?.blocks[0]?.parameters[0]?.name
                : currentItem?.blocks[0]?.name;
    }

    return !!(selectedFieldTrigger?.length && name && location && abstractUuid);
};

export const isValidCloudVariableBlock = (currentItem) => {
    const {
        comparingValue,
        selectedFieldTrigger,
        selectedComparator,
        selectedVariable,
        selectedAbstract,
        selectedIntegrationId,
        selectedValueType,
        selectedVariableCompared,
        selectedAbstractForCompared,
    } = currentItem;
    const isValidCloudVariableOnChangeBlock = selectedComparator === ON_CHANGE;

    if (selectedValueType === CLOUD_VARIABLE) {
        return !!(
            (selectedFieldTrigger?.length &&
                selectedComparator?.length &&
                selectedVariableCompared?.length &&
                selectedAbstractForCompared?.uuid?.length &&
                selectedIntegrationId?.length) ||
            isValidCloudVariableOnChangeBlock
        );
    } else {
        return !!(
            (selectedFieldTrigger?.length &&
                comparingValue?.length &&
                selectedComparator?.length &&
                selectedVariable?.length &&
                selectedAbstract?.uuid?.length &&
                selectedIntegrationId?.length) ||
            isValidCloudVariableOnChangeBlock
        );
    }
};

export const isValidDateAndTimeBlock = (currentItem) => {
    const { blocks, selectedFieldDate, selectedFieldTrigger, selectedOperator } = currentItem;

    const timezone = blocks?.[0]?.parameters?.[0]?.parameters?.[1]?.timezone || blocks?.[0]?.parameters?.[1]?.timezone;

    const measurement =
        blocks?.[0]?.parameters?.[0]?.parameters?.[0]?.measurement || blocks?.[0]?.parameters?.[0]?.measurement;

    const times =
        blocks?.[0]?.parameters?.[0]?.parameters?.[0]?.times?.length || blocks?.[0]?.parameters?.[0]?.times?.length;

    const offset = blocks?.[0]?.parameters?.[0]?.parameters?.[0]?.offset || blocks?.[0]?.parameters?.[0]?.offset;

    const isOffsetZero =
        blocks?.[0]?.parameters?.[0]?.parameters?.[0]?.offset === 0 || blocks?.[0]?.parameters?.[0]?.offset === 0;

    const days =
        blocks?.[0]?.parameters?.[0]?.parameters?.[0]?.days?.length || blocks?.[0]?.parameters?.[0]?.days?.length;

    const year = blocks?.[0]?.parameters?.[0]?.parameters?.[0]?.year || blocks?.[0]?.parameters?.[0]?.year;

    if (blocks?.[0]?.parameters?.[0]?.parameters?.[0]?.days || blocks?.[0]?.parameters?.[0]?.days) {
        return !!(
            blocks?.length &&
            selectedFieldDate?.length &&
            selectedFieldTrigger?.length &&
            selectedOperator?.length &&
            timezone &&
            days
        );
    }

    return !!(
        (blocks?.length &&
            selectedFieldDate?.length &&
            selectedFieldTrigger?.length &&
            selectedOperator?.length &&
            timezone &&
            measurement) ||
        times ||
        offset ||
        isOffsetZero ||
        year
    );
};

export const isNestedTriggerChanged = (currentItem, currentSceneTrigger) => {
    const currentSceneTriggerForComp = _.flow([
        Object.entries,
        (arr) => arr.filter(([key]) => key != 'id'),
        Object.fromEntries,
    ])(currentSceneTrigger);

    const isNotEqual =
        (currentSceneTrigger?.name === NOT && !currentItem.not) ||
        (currentItem.not && currentSceneTrigger?.name !== NOT);

    return isCurrentSubscription(currentItem)
        ? isNucalSubscriptionChanged(currentItem) || isNotEqual
        : !_.isEqual(currentSceneTriggerForComp, currentItem.blocks?.[0]) || isNotEqual;
};

const isCurrentSubscription = (currentItem) => {
    return currentItem?.subscription ? currentItem?.subscription : currentItem?.subscriptionDataFromKvs;
};

const isNucalSubscriptionChanged = (currentItem) => {
    const currentSubscription = currentItem?.subscription
        ? currentItem?.subscription
        : currentItem?.subscriptionDataFromKvs;
    const initialSubscription = currentItem?.initialSubscriptionDataFromKvs;

    const currentParams = currentSubscription?.parameters?.params || currentSubscription?.params?.parameters?.params;
    const isSubscriptionEqual =
        !_.isEqual(initialSubscription?.parameters?.params, currentParams) ||
        initialSubscription?.parameters?.name !== currentSubscription?.params?.parameters?.name;

    return currentItem?.subscriptionDataFromKvs ? false : isSubscriptionEqual;
};

export const convertAmPmOfParametersTo24HourFormat = (parameters, timesNode, timeNode, hourNode, offset, isPm) => {
    return parameters.map((item) => {
        let keys = Object.keys(item);
        if (item.offset === ZERO_INT) {
            keys = keys.filter((item) => ![timeNode].includes(item));
        }

        const isTime = [];
        let hourInTwentyFourHourFormat = ZERO_INT;

        if (item.isPm) {
            if (item.hour) {
                if (item.hour === HOUR12 && item.minute >= ZERO_INT) {
                    hourInTwentyFourHourFormat = item.hour;
                } else {
                    hourInTwentyFourHourFormat = item.hour + HOUR12;
                }
            }

            if (item.time) {
                if (item?.time[0] === HOUR12 && item?.time[1] >= ZERO_INT) {
                    isTime.push(item?.time[0], item?.time[1]);
                } else {
                    isTime.push(item.time[0] + HOUR12, item.time[1]);
                }
            }

            if (item.times) {
                const isTimes = [];
                if (item?.times[0][0] === HOUR12 && item?.times[0][1] >= ZERO_INT) {
                    isTimes.push(item?.times[0][0], item?.times[0][1]);
                    isTime.push(isTimes);
                } else {
                    isTimes.push(item.times[0][0] + HOUR12, item.times[0][1]);
                    isTime.push(isTimes);
                }
            }
        }

        if (!item.isPm) {
            if (item.hour) {
                if (item.hour === HOUR12 && item.minute >= ZERO_INT) {
                    hourInTwentyFourHourFormat = item.hour - item.hour;
                } else {
                    hourInTwentyFourHourFormat = item.hour;
                }
            }

            if (item.time) {
                if (item?.time[0] === HOUR12 && item?.time[1] >= ZERO_INT) {
                    isTime.push(item?.time[0] - HOUR12, item?.time[1]);
                } else {
                    isTime.push(item?.time[0], item?.time[1]);
                }
            }

            if (item.times) {
                const isTimes = [];
                if (item?.times[0][0] === HOUR12 && item?.times[0][1] >= ZERO_INT) {
                    isTimes.push(item?.times[0][0] - HOUR12, item?.times[0][1]);
                    isTime.push(isTimes);
                } else {
                    isTimes.push(item?.times[0][0], item?.times[0][1]);
                    isTime.push(isTimes);
                }
            }
        }

        if (Object.keys(item).includes(isPm)) {
            keys = keys.filter((item) => ![isPm].includes(item));
        }

        return keys.reduce((acc, key) => {
            // TODO remove after fix bug on cloud [ENMC-4676]
            if (key === offset && item[key] === ZERO_INT) {
                return {
                    ...acc,
                    [key]: item[key],
                    time: [0, 0],
                };
            }

            if ((item.isPm && key === timesNode) || key === timeNode) {
                return {
                    ...acc,
                    [key]: isTime,
                };
            } else if ((!item.isPm && key === timesNode) || key === timeNode) {
                return {
                    ...acc,
                    [key]: isTime,
                };
            } else if (key === hourNode) {
                return {
                    ...acc,
                    [key]: hourInTwentyFourHourFormat,
                };
            } else {
                return {
                    ...acc,
                    [key]: item[key],
                };
            }
        }, {});
    });
};

export const isTriggerChanged = (currentItem, currentSceneTrigger) => {
    if (currentSceneTrigger.name === SCHEDULE) {
        const newCurrentItem = _.cloneDeep(currentItem);
        const newCurrentItemBlocks = _.cloneDeep(currentItem.blocks);
        const [currentItemBlock] = newCurrentItemBlocks;
        const { parameters } = currentItemBlock;

        const updatedParameters = convertAmPmOfParametersTo24HourFormat(
            parameters || [],
            TIMES_NODE,
            TIME_NODE,
            HOUR_NODE,
            OFFSET,
            IS_PM,
        );

        newCurrentItemBlocks[0].parameters = updatedParameters;

        if (currentItem.not) {
            newCurrentItem.blocks = [
                {
                    name: OPERATOR_NOT,
                    parameters: updatedParameters,
                },
            ];

            return !_.isEqual(newCurrentItem?.blocks[0], currentSceneTrigger.parameters?.[0]);
        }
    }

    if (currentItem.not) {
        const newBLocks = [
            {
                name: OPERATOR_NOT,
                parameters: currentItem.blocks,
            },
        ];

        return !_.isEqual(newBLocks[0], currentSceneTrigger);
    }

    return isCurrentSubscription(currentItem)
        ? isNucalSubscriptionChanged(currentItem)
        : !_.isEqual(currentItem?.blocks?.[0], currentSceneTrigger);
};

export const dataForDevice = (device, variable, capabilityName, uuid) => {
    return {
        id: device?.uuid || device?.idDev || uuid,
        capability: capabilityName ? capabilityName : device?.capabilities?.[0].replace('_command', ''),
        variable: variable[0],
    };
};

export const createDataForUpdateCloudDevice = (
    device,
    createBlock,
    nameBlock,
    filterCapabilities,
    variables,
    currentCapabilities,
) => {
    return {
        name: device?.name || device?.deviceName,
        idDev: device?.uuid || device?.abstract,
        blocks: createBlock,
        nameBlock,
        nameSubBlock: nameBlock,
        capabilities: filterCapabilities,
        variables,
        currentVariable: variables[0],
        currentVariableType: currentCapabilities[0]?.definition?.variables[variables[0]].type,
        capability: nameBlock,
    };
};

export const getCurrentCapabilities = (capabilities, CapibilityReplaceCommand) => {
    return capabilities && capabilities.filter((item) => item.capability_name === CapibilityReplaceCommand);
};

export const createObjectsWithVariables = (currentCapabilities) => {
    return currentCapabilities.map((item) => {
        return {
            name: item,
            value: 0,
        };
    });
};

export const getCurrentCapabilitiesForBlocksInDevice = (
    listCapabilities,
    filterCapabilities,
    currentDeviceCapability,
) => {
    return (
        listCapabilities &&
        listCapabilities.filter((item) =>
            currentDeviceCapability
                ? item?.capability_name === currentDeviceCapability
                : item?.capability_name === filterCapabilities[0],
        )
    );
};

export const getCurrentCapabilitiesForVariable = (listCapabilities, currentItem) => {
    return listCapabilities.filter((item) => item.capability_name === currentItem.nameSubBlock)[0];
};

export const returnDataForCloudVariableField = (value, currentCapabilitiesForVariable) => {
    return {
        currentVariable: value,
        currentVariableType: currentCapabilitiesForVariable?.definition?.variables[value]?.type,
    };
};

export const returnDataForCloudNameSubBlockField = (value, variables, currentCapabilities) => {
    return {
        nameSubBlock: value,
        currentVariable: variables[0],
        currentVariableType: currentCapabilities?.definition?.variables[variables[0]]?.type,
        variableValue: '',
        variables: createObjectsWithVariables(variables),
    };
};

export const returnDataForCloudVariableValueField = ({ value, currentVariableType, currentItem, isValidRgbValue }) => {
    return {
        variableValue: value,
        currentVariableType,
        abstract: currentItem.idDev,
        isValidRgbValue,
    };
};

export const returnDataForNotificationTemplateVariableValueField = (value, currentVariableType, currentItem) => {
    return {
        variableValue: '',
        currentVariable: value,
        currentVariableType,
        abstract: currentItem.idDev,
    };
};

export const getDataForBlocksInCloudDevice = (abstractDeviceTriggers, device, listCapabilities, operator) => {
    const currentDevice = abstractDeviceTriggers?.find((item) => item?.uuid === getDeviceUUID(device));
    // eslint-disable-next-line prefer-destructuring
    const nameBlock = filterCapabilitiesForCloudTriggers(currentDevice?.capabilities)[0];
    const filterCapabilities = filterCapabilitiesForCloudTriggers(device?.capabilities);
    const currentCapabilities = getCurrentCapabilitiesForBlocksInDevice(
        listCapabilities,
        filterCapabilities,
        nameBlock,
    );

    const variables =
        Object.keys(currentCapabilities)?.length && Object.keys(currentCapabilities?.[0]?.definition?.variables);
    const createBlock = templateBlock(
        operator,
        dataForDevice(device, variables, nameBlock, currentDevice?.uuid),
        {},
        variables,
    );

    return createDataForUpdateCloudDevice(
        device,
        createBlock,
        nameBlock,
        filterCapabilities,
        variables,
        currentCapabilities,
    );
};

export const returnDataForBlocksInCloudDevice = (listCapabilities, operator) => {
    return {
        idDev: NOTIFICATION_TEMPLATE_ABSTRACT_UUID,
        blocks: templateBlock(operator),
        capabilities: listCapabilities,
    };
};

export const returnDataForUpdateTriggerNameBlock = (
    listCapabilities,
    currentItem,
    idGroup,
    abstractDeviceTriggers,
    field,
    value,
    typeMeshBot,
    capabilitiesList,
    isValidRgbValue,
) => {
    let data = {};
    const getBooleanValue = typeof isValidRgbValue === TYPE_FIELD_BOOLEAN ? isValidRgbValue : true;

    const currentCapabilitiesForVariable = getCurrentCapabilitiesForVariable(listCapabilities, currentItem);
    if (field === at.VARIABLE) {
        data = returnDataForCloudVariableField(value, currentCapabilitiesForVariable);
    } else if (field === at.NAME_SUB_BLOCK && typeMeshBot === CLOUD_MESHBOT) {
        const currentCapabilities = listCapabilities.filter((item) => item.capability_name === value)?.[
            DATA_FROM_VALUE_FROM_FIRST_BLOCK
        ];

        const variables = Object.keys(currentCapabilities?.definition?.variables);

        data = returnDataForCloudNameSubBlockField(value, variables, currentCapabilities);
    } else if (field === at.NOTIFICATION_TEMPLATE_CAPABILITY) {
        const currentVariables = capabilitiesList.filter((capability) => capability.capability_name === value)[0]
            .definition.variables;

        data = {
            nameSubBlock: value,
            variables: Object.keys(currentVariables),
            currentVariable: Object.keys(currentVariables)[DATA_FROM_VALUE_FROM_FIRST_BLOCK],
            currentVariableType:
                Object.entries(currentVariables)?.[DATA_FROM_VALUE_FROM_FIRST_BLOCK]?.[
                    DATA_FROM_VALUE_FROM_SECOND_BLOCK
                ]?.type,
        };
    } else if (field === at.NOTIFICATION_TEMPLATE_OPTION) {
        data = {
            capabilities: listCapabilities,
            typeOfNotificationTemplate: value,
        };
    } else if (field === at.VARIABLE_VALUE) {
        const currentVariableType =
            currentCapabilitiesForVariable?.definition?.variables[value]?.type ||
            currentCapabilitiesForVariable?.definition?.variables[currentItem.currentVariable]?.type;

        data = returnDataForCloudVariableValueField({
            value,
            currentVariableType,
            currentItem,
            isValidRgbValue: getBooleanValue,
        });
    } else if (field === at.NOTIFICATION_TEMPLATE_VARIABLE) {
        const currentVariableType = currentCapabilitiesForVariable?.definition?.variables[value]?.type;
        data = returnDataForNotificationTemplateVariableValueField(value, currentVariableType, currentItem);
    } else if (field === at.NOTIFICATION_TEMPLATE_VARIABLE_VALUE) {
        data = { value, isValidRgbValue: getBooleanValue };
    }

    return data;
};

export const selectedFieldDateValue = (selectedFieldDate) => {
    let currentValue = '';
    if (dateAndTimeNames.includes(selectedFieldDate)) {
        currentValue = selectedFieldDate;
    } else if (selectedFieldDate === 'date') {
        currentValue = 'isOnce';
    }

    return currentValue;
};

export const checkIsMeshBotChecked = (groupActions) => {
    return groupActions.filter((action) => action.selectedFieldTrigger === MESHBOT_NODE_TYPES.MESHBOT_STATE);
};

export const isPageValue = () => {
    const pageVal = localStorage.getItem(localStorageKeys.PAGE_VALUE);

    if (pageVal === EZLOGIC || pageVal === NOTIFICATIONS || pageVal === MESHBOTS_PAGE_VALUE) {
        return true;
    } else {
        return false;
    }
};

export const isIntegrationsPage = (location) => {
    const { pathname } = location;
    const regex = /integration/;

    return regex.test(pathname);
};

export const getPageValue = () => {
    const pageValue = localStorage.getItem(localStorageKeys.PAGE_VALUE);
    if (pageValue === EZLOGIC) {
        return true;
    } else if (pageValue === NOTIFICATIONS) {
        return NOTIFICATIONS;
    } else {
        return false;
    }
};

export const checkUuidFormat = (hostname) => {
    const splittedDomain = splitDomainByDot(hostname);
    const isUuidFormat = UUID_REGEXP.test(splittedDomain[ZERO]);

    return isUuidFormat;
};

export const checkUuidValidationFormat = (splittedDomain) => {
    const isUuidValidFormat = UUID_VALIDATION_REGEXP.test(splittedDomain[ZERO]);

    return isUuidValidFormat;
};

export const splitDomainByDot = (domain) => {
    return domain?.split(DOT);
};

export const joinDomainByDot = (domain) => {
    return domain?.join(DOT);
};

export const getDomainForIncorrectUuidFormat = (redirectDomain, protocol) => {
    return `${protocol}//${redirectDomain}`;
};

export const getRootDomain = (domain) => {
    const splittedDomain = splitDomainByDot(domain);
    const copyOfSplittedDomain = [...splittedDomain];
    copyOfSplittedDomain.shift();
    const rootDomain = joinDomainByDot(copyOfSplittedDomain);

    return rootDomain;
};

export const getPartnerUuidFromDomain = (domain) => {
    const splittedDomain = splitDomainByDot(domain);
    const isValidUuidFormat = checkUuidValidationFormat(splittedDomain);
    if (isValidUuidFormat) {
        return splittedDomain[ZERO];
    } else {
        return null;
    }
};

export const setTLD = () => {
    localStorage.setItem(localStorageKeys.LOCALSTORAGE_TOP_LEVEL_DOMAIN, true);
};

export const removePartnerDomainDataFromLocalStorage = () => {
    localStorage.removeItem(localStorageKeys.DOMAIN_PARTNER_UUID);
    localStorage.removeItem(localStorageKeys.LOCALSTORAGE_TOP_LEVEL_DOMAIN);
};

export const getTheme = (colorObject) => {
    const updatedTheme = customTheme(colorObject);

    return updatedTheme;
};

export const validateDataLoading = ({ ezlo, app }) => {
    const isConnecting = ezlo?.isConnecting;
    const { lineLoading } = app;
    if (!_.isBoolean(lineLoading) || !_.isBoolean(isConnecting)) {
        throw new Error('lineLoading or isConnecting are not Boolean. validateDataLoading');
    }

    return lineLoading || isConnecting;
};

/**
 * Function for determining single or more than one onChange trigger
 * @param {Array} cloudTriggers - data regarding cloud triggers
 * @param {String} selectedOperator - type of operation selected by user
 * @param {String} [optionType] - type of option selected by user AND/OR
 * @returns {Boolean} Returns boolean for whether the triggers have a single or more than one onChange block when the option selected is AND
 * */
export const checkForSingleOnChangeTrigger = (cloudTriggers, selectedOperator, optionType) => {
    if (cloudTriggers.length <= SINGLE_TRIGGER) {
        return true;
    }

    const onChangeTriggerCount = cloudTriggers.reduce((counter, obj) => {
        if (
            obj?.blocks?.[INDEX_OF_ZERO]?.name === ON_CHANGE &&
            (obj.selectedFieldTrigger === DEVICE_STATE ||
                obj.selectedFieldTrigger === CLOUD_VARIABLES ||
                obj.selectedFieldTrigger === MESHBOT_NODE_TYPES.PAAS)
        ) {
            counter += INCREMENT_BY_ONE;
        }

        return counter;
    }, INITIAL_VALUE_FOR_COUNTER);

    if (selectedOperator === AND) {
        return !(onChangeTriggerCount > SINGLE_TRIGGER);
    }

    if ((selectedOperator === ON_CHANGE || selectedOperator === MESHBOT_NODE_TYPES.PAAS) && optionType === AND) {
        return !(onChangeTriggerCount >= SINGLE_TRIGGER);
    }

    return true;
};

/**
 * Function for counting NuCAL Triggers
 * @param {Array} cloudTriggers - data regarding cloud triggers
 * @returns {Number} Returns NuCAL trigger count
 * */
export const getOnChangeNucalTriggerCount = (cloudTriggers) => {
    return cloudTriggers.reduce((counter, obj) => {
        if (obj?.blocks?.[INDEX_OF_ZERO]?.name === ON_CHANGE && obj.selectedFieldTrigger === MESHBOT_NODE_TYPES.PAAS) {
            counter += INCREMENT_BY_ONE;
        }

        return counter;
    }, INITIAL_VALUE_FOR_COUNTER);
};

/**
 * Function for counting onChange triggers excluding NuCAL triggers
 * @param {Array} cloudTriggers - data regarding cloud triggers
 * @returns {Number} Returns onChange trigger count excluding NuCAL
 * */
export const getOnChangeTriggersNotNucalCount = (cloudTriggers) => {
    return cloudTriggers.reduce((counter, obj) => {
        if (
            obj?.blocks?.[INDEX_OF_ZERO]?.name === ON_CHANGE &&
            (obj.selectedFieldTrigger === DEVICE_STATE || obj.selectedFieldTrigger === CLOUD_VARIABLES)
        ) {
            counter += INCREMENT_BY_ONE;
        }

        return counter;
    }, INITIAL_VALUE_FOR_COUNTER);
};

/**
 * Function for determining toast message according to the triggers
 * @param {Array} cloudTriggers - data regarding cloud triggers
 * @param {String} [selectedOperator] - type of operation selected by user
 * @returns {String} Returns warning message according to user interaction
 * */
export const determineToastMessageAccordingToTriggerNodeType = (cloudTriggers, selectedOperator) => {
    const onChangeNucalTriggerCount = getOnChangeNucalTriggerCount(cloudTriggers);
    const onChangeTriggersNotNucalCount = getOnChangeTriggersNotNucalCount(cloudTriggers);

    if (
        onChangeNucalTriggerCount >= SINGLE_TRIGGER &&
        onChangeTriggersNotNucalCount === ZERO &&
        selectedOperator !== ON_CHANGE
    ) {
        return EZLOGIC_TOAST_MULTIPLE_NUCAL_WARNING;
    }

    if (
        onChangeNucalTriggerCount >= SINGLE_TRIGGER &&
        (onChangeTriggersNotNucalCount >= ZERO || selectedOperator === ON_CHANGE)
    ) {
        return EZLOGIC_TOAST_NUCAL_COMBINING_ANOTHER_ONCHANGE_TRIGGER_WARNING;
    }

    if (onChangeTriggersNotNucalCount >= SINGLE_TRIGGER && selectedOperator === MESHBOT_NODE_TYPES.PAAS) {
        return EZLOGIC_TOAST_NUCAL_COMBINING_ANOTHER_ONCHANGE_TRIGGER_WARNING;
    }

    return EZLOGIC_TOAST_MULTIPLE_ONCHANGE_WARNING;
};

/**
 * This function checks entity name uniqueness
 * @param {array} entityList - the entity list
 * @param {string} name - the new entity name
 * @returns {boolean}
 */
export const isEntityNameUnique = (entityList = [], name) => {
    return !entityList.some((entity) => entity.name === name);
};
/**
 * Builds an updated item based on the 'hub.item.dictionary.updated' broadcast result.
 * @param {Object} obsoleteItem - The obsolete item.
 * @param {Object} broadcastResult - The broadcast result.
 * @returns {Object|undefined} The updated item or undefined if invalid parameters are provided.
 */
export const buildItemByHubItemDictionaryUpdatedBroadcast = (obsoleteItem, broadcastResult) => {
    if (!obsoleteItem || !broadcastResult) {
        return;
    }

    if (broadcastResult?.name === ITEM_NAMES.USER_CODE && broadcastResult?.elementType === ELEMENT_TYPES.USER_CODE) {
        return buildUpdatedUserCodesItemByBroadcast(obsoleteItem, broadcastResult);
    }
};
/**
 * Builds an updated user codes item based on the 'hub.item.dictionary.updated' broadcast result.
 * @param {Object} obsoleteItem - The obsolete item.
 * @param {Object} broadcastResult - The broadcast result.
 * @returns {Object|undefined} The updated user codes item or undefined if invalid parameters are provided.
 */
export const buildUpdatedUserCodesItemByBroadcast = (obsoleteItem, broadcastResult) => {
    const { operation, element } = broadcastResult || {};

    if (!operation || !isObject(element)) {
        return;
    }

    if (operation === OPERATIONS.ADDED || operation === OPERATIONS.UPDATED) {
        const newValues = Object.entries(element).reduce((prevState, [elementKey, element]) => {
            if (!element.hasOwnProperty(VALUE)) {
                return prevState;
            }

            return {
                [elementKey]: element.value,
            };
        }, {});

        return {
            ...obsoleteItem,
            value: {
                ...obsoleteItem.value,
                ...newValues,
            },
        };
    }

    if (operation === OPERATIONS.REMOVED) {
        const value = cloneDeep(obsoleteItem.value);
        Object.keys(element).forEach((key) => {
            delete value[key];
        });

        return { ...obsoleteItem, value: value || {} };
    }
};

/**
 * Updates the favicon of the document with the specified icon URL.
 *
 * This function creates a new <link> element with a `rel` attribute set to 'icon'
 * and a `href` attribute set to the provided iconUrl. If an existing favicon
 * link is present in the document, it is removed before adding the new one.
 *
 * @param {string} iconUrl - The URL of the new favicon to be set.
 */
export const updateFavicon = (iconUrl) => {
    const link = document.createElement('link');
    const oldLink = document.querySelector("link[rel='icon']");
    if (oldLink) {
        document.head.removeChild(oldLink);
    }
    link.rel = 'icon';
    link.href = iconUrl;
    document.head.appendChild(link);
};

/**
 * Updates the favicon based on the TLD status and provided KVS status.
 *
 * This function updates the favicon depending on whether the current domain
 * is a top-level domain (TLD) and the value of `kvsStatus`. If the domain
 * is a TLD:
 * - If `kvsStatus` is `null`, it sets an empty favicon.
 * - If `kvsStatus` is equal to 1 and a custom favicon is provided,
 *   it sets the custom favicon.
 * - Otherwise, it sets the default favicon.
 * If the domain is not a TLD, it always sets the default favicon.
 *
 * @param {number|null} kvsStatus - The KVS status to determine which favicon to set.
 * @param {string} customizedFaviconIcon - The path to a customized favicon icon.
 */
export const handleFaviconUpdate = (kvsStatus, customizedFaviconIcon) => {
    if (isTLD()) {
        if (kvsStatus === null) {
            updateFavicon('');
        } else if (kvsStatus === ONE_INT) {
            if (customizedFaviconIcon) {
                updateFavicon(customizedFaviconIcon);
            } else {
                updateFavicon('/assets/favicon.ico');
            }
        } else {
            updateFavicon('/assets/favicon.ico');
        }
    } else {
        updateFavicon('/assets/favicon.ico');
    }
};

/**
 * Checks if the current hostname is a top-level domain (TLD).
 *
 * This function examines the current hostname from the window location
 * and compares it against a list of known domains defined in
 * `EZLOGIC_KNOWN_DOMAINS`. If the hostname is not found in this list,
 * it is considered a TLD.
 *
 * @returns {boolean} Returns `true` if the hostname is a TLD, `false` otherwise.
 */
export const isTLD = () => {
    const { hostname } = window?.location;
    const domain = EZLOGIC_KNOWN_DOMAINS[hostname];
    if (domain === undefined) {
        return true;
    }

    return false;
};
