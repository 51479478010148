import { axiosCloudConfig } from '../api/config';
import at from '../constants/ActionTypes/Main';
import { API_CLOUD_EZLO_REQUEST_URL } from '../constants/URLs';
import {
    buildIntegrationsListRequestBody,
    buildIntegrationsPreviewsListRequestBody,
} from '../containers/Ezlo/EzloParjectsSettings/utils';

import { PAASAuth } from '../containers/Ezlo/EzloRule/EditForm/RuleSettings/components/PAAS/paas-constants';
import { getPaasAuthEnrollRequestParams } from '../containers/Ezlo/EzloRule/EditForm/RuleSettings/components/PAAS/PaasAPI/paas-request-params-generators';
import { getPaasAuthIntegrationUuid } from '../containers/Ezlo/EzloRule/EditForm/RuleSettings/components/PAAS/utils';
import { bugsnagNotifyWrapper } from '../containers/ErrorBoundary/utils';
import { buildAbstractListDetailedRequestBody } from '../containers/Ezlo/EzloVirtualDeviceManager/utils';
import { extractCachedData } from '../helpers/mainActionUtils';
import { isArray, isObject } from 'lodash';

const actions = {
    hideModal: () => (dispatch) => dispatch({ type: at.HIDE_MODAL }),
    // TODO: naming from lower case
    ShowCreateRule: () => (dispatch) => dispatch({ type: at.IS_SHOW_MODAL_CREATE_RULE }),
    // TODO: naming from lower case
    ShowClearMeshBot: () => (dispatch) => dispatch({ type: at.IS_SHOW_MODAL_CLEAR_MESH_BOT }),
    // TODO: naming from lower case
    ShowDeviceFunction: () => (dispatch) => dispatch({ type: at.IS_SHOW_MODAL_DEVICE }),
    // TODO: rewrite using async/await
    showNavigation: () => ({ type: at.SHOW_NAVIGATION }),

    hideNavigation: () => ({ type: at.HIDE_NAVIGATION }),

    getAbstractCapabilities: () => async (dispatch) => {
        dispatch({ type: at.GET_ABSTRACT_CAPABILITIES_LIST });

        new Promise((resolve) => {
            const fetchStorageList = () => {
                fetch(API_CLOUD_EZLO_REQUEST_URL(), {
                    method: 'post',
                    ...axiosCloudConfig(),
                    body: JSON.stringify({
                        call: 'abstract_capabilities_list',
                        version: '1',
                        params: {},
                    }),
                })
                    .then((res) => {
                        return res.json();
                    })
                    .then((payload) => {
                        const abstractCapabilities = payload.data.capabilities;
                        if (payload.status === 0) {
                            throw new Error(payload.data.error_text);
                        } else if (payload.status === 1) {
                            dispatch({
                                type: at.GET_ABSTRACT_CAPABILITIES_LIST_SUCCESS,
                                abstractCapabilities,
                            });
                            resolve();
                        }
                    })
                    .catch((error) => {
                        bugsnagNotifyWrapper(error, { type: at.GET_ABSTRACT_CAPABILITIES_LIST_ERROR });
                        dispatch({ type: at.GET_ABSTRACT_CAPABILITIES_LIST_ERROR, error });
                    });
            };

            fetchStorageList();
        });
    },

    getAbstractsList: (initialAbstractList) => async (dispatch) => {
        dispatch({ type: at.GET_ABSTRACTS_LIST, payload: initialAbstractList });
        try {
            const abstractListPromise = await fetch(API_CLOUD_EZLO_REQUEST_URL(), {
                method: 'post',
                ...axiosCloudConfig(),
                body: JSON.stringify(buildAbstractListDetailedRequestBody()),
            });
            const abstractsList = await abstractListPromise.json();

            const {
                data: { abstracts },
            } = abstractsList;
            const abstractCapabilities =
                abstracts?.reduce((acc, { uuid, capabilities }) => ({ ...acc, [uuid]: capabilities }), {}) || {};

            dispatch({
                type: at.GET_ABSTRACTS_LIST_SUCCESS,
                payload: { abstractsList: abstracts, abstractCapabilities },
            });

            return abstractsList;
        } catch (error) {
            bugsnagNotifyWrapper(error, { type: at.GET_ABSTRACTS_LIST_ERROR });
            dispatch({ type: at.GET_ABSTRACTS_LIST_ERROR, payload: { error } });

            return error;
        }
    },

    getIntegrationsList: () => async (dispatch) => {
        dispatch({ type: at.GET_INTEGRATIONS_LIST });

        try {
            const integrations = await extractCachedData(buildIntegrationsListRequestBody());
            const integrationPreviews = await extractCachedData(buildIntegrationsPreviewsListRequestBody());

            if (!isObject(integrations)) {
                return;
            }

            Object.entries(integrations).forEach(([key, integration]) => {
                const methods = integrationPreviews?.[key]?.call_definition?.list;
                const capabilities = integrationPreviews?.[key]?.nucal_parject_definition?.list?.interactions;
                const info = integrationPreviews?.[key]?.nucal_parject_definition?.list?.authentication;
                const subscribe_definition = integrationPreviews?.[key]?.subscribe_definition?.list?.length > 0;
                const parjectDefinitionList = integrationPreviews?.[key]?.nucal_parject_definition?.list;

                integration.uuid = key;
                integration.authType = info?.type || 'none';
                integration.icon = parjectDefinitionList?.imageSource || '';
                integration.authExplanation = info?.explanation || '';
                integration.description = parjectDefinitionList?.description || '';
                integration.title = parjectDefinitionList?.friendlyName || '';
                integration.capabilities = capabilities || [];
                integration.hasCallBack = subscribe_definition;
                integration.methods = methods;
                integration.interactions = integrationPreviews?.[key]?.nucal_parject_definition?.list?.interactions;
                integration.category = parjectDefinitionList?.category;
            });

            const integrationListWithCallBack = Object.values(integrations).filter((key) => key.hasCallBack == true);

            dispatch({
                type: at.GET_INTEGRATIONS_LIST_SUCCESS,
                payload: {
                    integrations: Object.values(integrations),
                    integrationsWithCallBack: integrationListWithCallBack,
                },
            });
        } catch (error) {
            bugsnagNotifyWrapper(error, { type: at.GET_INTEGRATIONS_LIST_ERROR });
            dispatch({ type: at.GET_INTEGRATIONS_LIST_ERROR, error });

            return error;
        }
    },

    // TODO: apply refactoring
    initialSetup: () => (dispatch) => {
        dispatch({ type: at.OEM_IS_LOADING });
        dispatch({ type: at.OEM_IS_INITIALIZED });
    },
    // TODO: apply refactoring
    enrollToPAASAuthService: () => async (dispatch, getState) => {
        const { main } = getState();

        const {
            paas,
            integrationList: { integrations },
            abstractsList: { abstracts },
        } = main;
        const { paasAuthServiceUuid } = paas;

        if (paasAuthServiceUuid || !isArray(integrations) || !isArray(abstracts)) {
            return;
        }

        dispatch({ type: at.ENROLL_TO_PAAS_AUTH });

        const enrollment = abstracts?.find(({ name }) => name === PAASAuth);

        if (enrollment?.uuid) {
            dispatch({
                type: at.ENROLL_TO_PAAS_AUTH_SUCCESS,
                payload: { paasAuthUuid: enrollment.uuid },
            });

            return;
        }

        const paasAuthIntegrationUuid = getPaasAuthIntegrationUuid(integrations);
        const cloudUrl = API_CLOUD_EZLO_REQUEST_URL();
        const paasAuthParams = getPaasAuthEnrollRequestParams(paasAuthIntegrationUuid);

        try {
            const paasEnrolmentResponse = await fetch(cloudUrl, paasAuthParams);
            const { data: paasEnrolment } = await paasEnrolmentResponse.json();

            dispatch({
                type: at.ENROLL_TO_PAAS_AUTH_SUCCESS,
                payload: { paasAuthUuid: paasEnrolment.uuid },
            });
        } catch (error) {
            bugsnagNotifyWrapper(error, { type: at.ENROLL_TO_PAAS_AUTH_ERROR });
            dispatch({ type: at.ENROLL_TO_PAAS_AUTH_ERROR, error });

            return error;
        }
    },

    setStatePageBeingEdited: (currentPageState) => (dispatch) =>
        dispatch({ type: at.SET_STATE_PAGE_BEING_EDITED, payload: currentPageState }),

    confirmUserAction: (cb) => (dispatch, getState) => {
        const { main } = getState();
        const { editableComponent } = main;

        if (editableComponent?.isDirty) {
            dispatch({ type: at.IS_SHOW_MODAL_CLEAR_MESH_BOT });
            dispatch({ type: at.SAVE_USER_ACTION, payload: cb });
        } else {
            cb();
        }
    },

    clearSavedAction: () => (dispatch) => dispatch({ type: at.CLEAR_SAVED_ACTION }),

    setConfirmationUser: (value) => (dispatch, getState) => {
        const { main } = getState();
        const { editableComponent } = main;

        if (Object.keys(editableComponent).length) {
            dispatch({ type: at.SET_CONFIRMATION_USER, payload: value });
        }
    },
};

export default actions;
